<template>
  <t-modal
    :name="`invoiceModal`"
    :variant="`2xl`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <div class="invoice-modal" ref="document">
      <a href="#" @click.prevent class="out-modalbox">
        <img src="@/assets/placeholder/mail.svg" alt="" />
        <span
          v-tooltip.top="
            `Sorry! This feature is disabled for development mode.`
          "
          >Send via Mail</span
        >
      </a>
      <a href="#" @click.prevent="exportToPDF" class="out-modalbox">
        <img src="@/assets/placeholder/download.svg" alt="" />
        <span>Download as PDF</span>
      </a>

      <div
        class="image-header"
        :style="`background-image: url('${getAssets({ type: 'header' })}');`"
      >
        <div>
          <img
            :src="getAssets({ type: 'logo' })"
            alt="header-background-image"
          />
          <h2 class="mt-4 text-gray-700">
            Thanks For Riding {{ trip.rider ? trip.rider.full_name : '' }}
          </h2>
          <span class="text-gray-700"> Hope you enjoy the ride </span>
        </div>
        <div>
          <span class="text-xs text-center text-gray-700">
            {{ getFormattedDateTime(trip.invoice.date, 'D MMM h:m:ss a') }}
          </span>
          <img
            src="@/assets/placeholder/scooter.svg"
            alt=""
            class="scooter-image"
          />
        </div>
      </div>
      <div class="content">
        <div class="px">
          <div class="cost-list">
            <div class="col-w-6">
              <span class="total-fare-text">Total Fare</span>
            </div>
            <div class="justify-end text-right col-w-6">
              <span class="total-fare-text cost-val">{{
                getFormattedPrice(trip.invoice.amount)
              }}</span>
            </div>
          </div>

          <div class="cost-list">
            <div class="col-w-8">
              <span class="cost-title"
                >Rental Cost ({{
                  milisecToDHMSConverter(trip.duration_in_seconds * 1000)
                }})</span
              >
            </div>
            <div class="justify-end text-right col-w-2">
              <span class="cost-val">{{
                getFormattedPrice(trip.settled_amount)
              }}</span>
            </div>
          </div>

          <div class="cost-list">
            <div class="col-w-8">
              <span class="cost-title" :style="'color:#3ebc30'">Discount </span>
            </div>
            <div class="justify-end text-right col-w-2">
              <span class="cost-val">{{
                getFormattedPrice(trip.discount)
              }}</span>
            </div>
          </div>
          <div class="cost-list">
            <div class="col-w-8">
              <span class="cost-title"
                >{{ getTaxFormattedTitle(trip.tax_percentage) }}
              </span>
            </div>
            <div class="justify-end text-right col-w-2">
              <span class="cost-val">
                {{ getFormattedPrice(trip.settled_tax_amount) }}</span
              >
            </div>
          </div>
          <div class="cost-list">
            <div class="col-w-8">
              <span class="cost-title">SubTotal </span>
            </div>
            <div class="justify-end text-right col-w-2">
              <span class="cost-val">{{
                getFormattedPrice(trip.invoice.amount)
              }}</span>
            </div>
          </div>

          <div class="cost-list no-border bg-gray-light br-8">
            <div class="col-w-7">
              <i class="fas fa-money-check"></i
              ><span class="ml-3 cost-title">Payment</span>
            </div>
            <div class="col-w-3" style="text-align: end">
              <span class="cost-val">{{ trip.invoice.payment_method }}</span>
            </div>
          </div>
          <div class="map_wrapper" v-if="haveTrip">
            <div class="directions">
              <div>
                <img src="@/assets/placeholder/from-icon.svg" alt="" />
                <div class="flex-col ml-10 flex-display">
                  <span class="mb-3">
                    {{
                      getFormattedDateTime(
                        trip.trip.pick_up_time,
                        'D MMM h:m:ss a'
                      )
                    }}
                  </span>
                  <span class="text-gray-500">
                    {{ trip.trip.pick_up_location }}
                  </span>
                </div>
              </div>
              <div class="mt-10">
                <img src="@/assets/placeholder/to-icon.svg" alt="" />
                <div class="flex-col ml-10 flex-display">
                  <span class="mb-3">
                    {{
                      getFormattedDateTime(
                        trip.trip.drop_off_time,
                        'D MMM h:m:ss a'
                      )
                    }}
                  </span>
                  <span class="text-gray-500">
                    {{ trip.trip.drop_off_location }}
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full ml-5 trip-map md-max:h-96">
              <gmap-map
                ref="map"
                :zoom="zoom"
                :center="center"
                map-type-id="terrain"
                style="width: 100%; height: 100%"
              >
                <gmap-marker
                  :position="origin"
                  :icon="{
                    url: require(`@/assets/img/start_place.png`),
                    scaledSize: { width: 35, height: 35 },
                  }"
                >
                </gmap-marker>
                <gmap-marker
                  :position="destination"
                  :icon="{
                    url: require(`@/assets/img/end_place.png`),
                    scaledSize: { width: 35, height: 45 },
                  }"
                >
                </gmap-marker>
              </gmap-map>
            </div>
          </div>
          <div class="copyright">
            <p class="mb-3 text-center text-10px">
              Visit the <a href="/">OTO page</a> for more information, including
              invoices (where available)
            </p>
            <img
              src="@/assets/placeholder/poweredbyoto.png"
              alt="powered by oto"
            />
          </div>
        </div>
      </div>
    </div>
  </t-modal>
</template>

<script>
import { getUTCAwareTime } from '@/utils'
import { gmapApi } from 'vue2-google-maps'
import html2pdf from 'html2pdf.js'
import {
  getHumanReadableDuration,
  getFormattedDateTime,
} from '@/utils/datetime'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils'

export default {
  name: 'InvoiceModal',

  props: {
    trip: {
      required: true,
      type: Object,
    },
    symbol: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      zoom: 19,
      center: {
        lat: 10,
        lng: 10,
      },
      data: null,
      show: false,
      pickupTime: '',
      dropOffTime: '',
      origin: null,
      destination: null,
    }
  },

  computed: {
    google: gmapApi,
    currencySymbol() {
      return this.trip?.invoice?.currency?.symbol
    },
    haveTrip() {
      if (this.trip.trip) {
        return true
      } else {
        return false
      }
    },
  },

  watch: {
    trip: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.origin = this.getLatLng(this.trip.trip.src_coord)
          this.destination = this.getLatLng(this.trip.trip.dest_coord)
          this.getDirection()

          let pos = this.getLatLng(this.trip.trip.dest_coord)
          this.center = {
            lat: pos.lat,
            lng: pos.lng,
          }
        }
      },
    },
  },

  methods: {
    getUTCAwareTime,
    getHumanReadableDuration,
    getFormattedDateTime,
    milisecToDHMSConverter,
    getFormattedPrice(rawPrice) {
      let _price = rawPrice

      if (typeof rawPrice !== 'number') {
        _price = parseFloat(rawPrice).toFixed(2)

        if (isNaN(_price)) {
          return '--'
        }
      } else {
        _price = rawPrice.toFixed(2)
      }

      return `${this.currencySymbol} ${_price}`
    },
    getTaxFormattedTitle(taxRate) {
      if (taxRate) {
        return `VAT ${parseFloat(taxRate).toFixed(0)}%`
      }

      return 'VAT'
    },

    async getDirection() {
      if (!this.trip.trip || !this.google) return
      const directionsService = new this.google.maps.DirectionsService()
      const directionsRenderer = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })
      directionsRenderer.setMap(this.$refs.map)

      await directionsService.route(
        {
          origin: this.getLatLng(this.trip.trip.src_coord),
          destination: this.getLatLng(this.trip.trip.dest_coord),
          travelMode: this.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(response)

            const leg = response.routes[0].legs[0]
            this.makeMarker(leg.start_location, 'start', 'Started from')
            this.makeMarker(leg.end_location, 'end', 'Ended at')
            return
          }

          this.makeMarker(
            response.request.origin.location,
            'start',
            'Started from'
          )
          console.log('direction response = ', response, status)
          // window.alert('Directions request failed due to ' + status)
        }
      )
    },

    makeMarker(position, icon, title) {
      new this.google.maps.Marker({
        position: position,
        map: this.$refs.map.$mapObject,
        icon:
          icon == 'start'
            ? {
                url: require(`@/assets/img/start_place.png`),
                scaledSize: { width: 35, height: 35 },
              }
            : {
                url: require(`@/assets/img/end_place.png`),
                scaledSize: { width: 35, height: 35 },
              },
        title: title,
      })
    },

    getLatLng(position) {
      if (typeof position !== 'string') return { lat: 0, lng: 0 }

      const [lat, lng] = position.split(',')
      // console.log(parseFloat(lat), lng)
      return { lat: parseFloat(lat), lng: parseFloat(lng) }
    },

    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: [0, 0, 0, -0.2],
        filename: `trip-${this.trip.id}.pdf`,
        image: { type: 'jpeg', quality: 0.9 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      })
    },

    getAssets({ type = 'logo' } = {}) {
      const org = this.$org?.alias?.toLowerCase() || 'otoride'

      const logos = {
        ignit: require('@/assets/brand/logo/ignit.png'),
        emoby: require('@/assets/brand/logo/emoby.png'),
        eskoot: require('@/assets/brand/logo/eskoot.png'),
        otoride: require('@/assets/brand/logo/otoride.png'),
      }

      const headers = {
        ignit: require('@/assets/brand/header/ignit.png'),
        emoby: require('@/assets/brand/header/emoby.png'),
        eskoot: require('@/assets/brand/header/eskoot.png'),
        otoride: require('@/assets/brand/header/otoride.png'),
      }

      if (type === 'logo') {
        return logos[org]
      } else if (type === 'header') {
        return headers[org]
      } else console.log('Invalid param supplied at getAssets()')
    },
  },
}
</script>

<style lang="scss">
.invoice-modal {
  .out-modalbox {
    background: #fff;
    padding: 7px 10px;
    z-index: 50;
    display: flex;
    transition: all 0.3s ease-in-out;

    // the position values below are placed by experimenting / simulating responsiveness on browser
    position: fixed !important;
    top: 50%;
    right: 0;
    @media (min-width: 850px) {
      right: 1%;
    }
    @media (min-width: 950px) {
      right: 5%;
    }
    @media (min-width: 1200px) {
      right: 12%;
    }
    @media (min-width: 1500px) {
      top: 45%;
      right: 14%;
    }

    span {
      transition: all 0.3s ease-in-out;
      font-size: 0;
    }
    &:hover {
      span {
        display: inline-block;
        width: auto;
        margin-left: 15px;
        right: 0;
        position: relative;
        font-size: 13px;
      }
    }
    & + .out-modalbox {
      top: calc(50% + 50px);
    }
    @media (min-width: 1500px) {
      & + .out-modalbox {
        top: calc(45% + 50px);
      }
    }
  }

  .image-header {
    min-height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding: 20px 70px 20px 55px;
    color: #fff;
    div {
      .date {
        font-size: 13px;
      }
      img {
        max-width: 140px;
        margin-top: 30px;
      }
      h2 {
        font-size: 24px;
      }
      span {
        font-size: 15px;
        margin-bottom: 27px;
      }
    }
  }
  .content {
    padding: 0 70px 16px;
  }
  .map_wrapper {
    margin-top: 20px;
    padding-bottom: 50px;
    display: flex;
    .directions {
      flex: 0 0 50%;
      div {
        display: flex;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .copyright {
    img {
      margin: auto;
    }
  }
  .total-fare-text {
    font-size: 24px;
    font-weight: bold;
    color: #121311;
  }
}

.rider-type-badge {
  @apply bg-blue-600;

  color: #fff;
  font-weight: 400 !important;
  font-size: 9.75px !important;
  text-transform: uppercase;
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 15px !important;

  &.guest {
    @apply bg-gray-400;
  }
}
</style>
